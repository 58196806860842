.collapse-content {
  margin: 0;
  border-bottom: 1px solid var(--wl-border-color-default);
}

.filter-v2.mobile-search-filters .collapse-content {
  border-bottom: 2px solid var(--tpp-srp-filters-border-color);
}

.collapse-content .criteria {
  display: block;
  font-size: var(--wl-font-size-x-small);
  color: var(--wl-text-color-five);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.collapse-content .header {
  background: none;
  padding: 1em;
  max-height: 1000000px;
}

.filter-v2 .collapse-content .header {
  padding: 20px 14px 16px;
}

.collapse-content .sort-title {
  padding: 12px 10px;
}

.collapse-content .header a {
  color: var(--wl-text-color-five);
  font-weight: bold;
  display: block;
  position: relative;
}

.filter-v2.three-column-listing .collapse-content .header span a, 
.filter-v2 .collapse-content .header span a {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--tpp-srp-filters-color);
  letter-spacing: 0.75px;
  line-height: 24px;
}

.filter-v2 .collapse-content > .header span a .icon,
.filter-v2 .collapse-content > .header h2 a .icon {
  background: url(../../icons/DownArrowBlack.svg) 50% 50% no-repeat;
  width: 25px;
  height: 25px;
  top: 0px;
}

.collapse-content .header span {
  font-size: 16px;
}

.collapse-content .header span,
.collapse-content .header h2 {
  margin: 0;
}

.collapse-content .header h2 a,
.collapse-content .header span a,
.collapse-content .header .custom-option {
  font-size: var(--wl-font-size-medium);
  outline: none;
  padding: 0;
}

.collapse-content .header a .icon {
  width: 13px;
  height: 7px;
  position: absolute;
  right: 0;
  top: 5px;
  display: none;
}

/* Second level */

.collapse-content .collapsible .header {
  padding-left: 20px;
}

.collapse-content .header a .icon {
  display: block;
}

.collapse-content > .header span a .icon,
.collapse-content > .header h2 a .icon {
  background: url(../../icons/DownArrow.svg) no-repeat;
}

.collapse-content.open > .header span a .icon,
.collapse-content.open > .header h2 a .icon {
  transform: rotate(180deg);
}

.collapse-content ul.opts li {
  padding-left: 30px;
}

.collapse-content .collapsible > .header .opts > li {
  font-size: var(--wl-font-size-medium);
  border: 0;
  padding: 0;
  vertical-align: middle;
}

.collapse-content .collapsible .header .custom-option {
  color: var(--wl-text-color-default);
}

.collapse-content .header span a:hover,
.collapse-content .header h2 a:hover {
  cursor: pointer;
}

/* open / close */

.collapse-content > .collapsible {
  max-height: 999px;
  transition: max-height 900ms;
  overflow: hidden;
  padding: 0px 15px;
}

.filter-v2 .collapse-content > .collapsible {
  max-height: 1120px;
}

.collapse-content.open > .collapsible {
  overflow: hidden;
}

.collapse-content.open > .collapsible.collapsible-price {
  overflow: unset;
}

.collapse-content > .collapsible.internal ul.opts {
  max-height: 160px;
}

.collapse-content > .collapsible.internal ul.opts {
  max-height: 160px;
}

.collapse-content > .collapsible.no-pad {
  padding: 0;
}

.collapse-content > .collapsible.double-height {
  max-height: 1998px;
}

.collapse-content.closed > .collapsible {
  max-height: 0;
  transition: max-height 500ms;
}

.filter-v2 .collapse-content > .collapsible.internal {
  padding-left: 0px;
}
