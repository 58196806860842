.option-item-button {
  align-items: center;
  background-color: var(--wl-brand-color-three);
  border: 0px solid var(--wl-brand-color-three);
  display: flex;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-default);
  padding: 8px 12px;
  text-align: initial;
  transition-duration: 0.4s;
  outline: none;
  width: 100%;
}

.option-item-button:focus {
  color: var(--wl-text-color-four);
  background-color: var(--wl-brand-color-one);
}

.option-item-button input[type="checkbox"] {
  margin-right: 10px;
}

.filter-v2 .collapse-content .collapsible .option-item-button {
  background-color: var(--tpp-srp-filters-dropdown-background-color);
}

.filter-v2 .collapse-content .collapsible .option-item-button:focus {
  background-color: var(--wl-brand-color-one);
}

.filter-v2 .collapse-content .collapsible .option-item-button:hover {
  background-color: var(--wl-brand-color-one);
}

.option-item-button.title {
  font-weight: 700;
  border-top: 1px solid #f2f2f2;
  width: 90%;
  margin: 0px 12px;
  padding: 8px 0px;
}

.option-set {
  display: none;
}

.option-set-open {
  background-color: var(--wl-brand-color-three);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: grid;
  flex-direction: column;
  position: absolute;
  width: 100%;
  min-width: fit-content;
  z-index: 200;
}

.option-set-scroll-area {
  max-height: 155px;
  overflow: hidden;
  overflow-y: auto;
  display: block;
  flex-direction: column;
  width: 100%;
  min-width: fit-content;
}

.option-set input {
  background: transparent;
  border: 1px solid var(--wl-border-color-default);
  border-radius: 4px;
  font-size: var(--wl-font-size-medium);
  margin: 4px;
  overflow: hidden;
  padding: 12px;
  text-align: left;
}

.option-set input#make-dropdown-filter {
  height: 18px;
  max-height: 18px;
}
