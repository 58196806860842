.collapse-content-details {
  border-top: 1px solid var(--wl-border-color-default);
  padding-left: 10px;
  padding-right: 10px;
}

.collapse-content-details.open {
  padding-bottom: 20px;
}

.collapse-content-details .header a {
  font-size: var(--wl-font-size-large);
  font-weight: 900;
  line-height: var(--wl-line-height-large);
  color: var(--wl-text-color-two);
  display: block;
  position: relative;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.collapse-content-details .header a .icon {
  width: 14px;
  height: 14px;
  position: relative;
  float: left;
  order: -1;
  padding-right: 10px;
}

.collapse-content-details > .header > :is(span, h1, h2, h3, h4, h5, h6) {
  cursor: pointer;
  display: block;
  margin: 8px 0;
  padding: 10px 0;
  font-size: 1.5em;
}

.collapse-content-details
  > .header
  > :is(span, h1, h2, h3, h4, h5, h6)
  a
  .icon {
  background: url(../../icons/Plus.svg) no-repeat;
}

.collapse-content-details.open
  > .header
  > :is(span, h1, h2, h3, h4, h5, h6)
  a
  .icon {
  background: url(../../icons/Minus.svg) no-repeat;
}

.collapse-content-details > .collapsible {
  transition: max-height 900ms;
  overflow: hidden;
  padding: 0px 10px;
}

@media screen and (min-width: 1097px) {
  .collapse-content-details > .collapsible {
    padding: 0px 26px;
  }
}

.collapse-content-details.closed > .collapsible {
  max-height: 0;
  transition: max-height 500ms;
}

.collapse-content-details.hide-title .header {
  display: none;
}

.collapse-content-details.hide-title .collapsible {
  margin-top: 20px;
}
