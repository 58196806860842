.dropdown-container {
  position: relative;
}

.dropdown-label {
  color: var(--wl-text-color-twelve);
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-small);
}

.dropdown-label-error {
  color: var(--wl-background-color-eight);
}

.dropdown-label-success {
  color: var(--wl-text-color-twelve);
}

.dropdown-input {
  background: transparent;
  border: none;
  color: var(--wl-text-color-twelve);
  cursor: pointer;
  font-size: var(--wl-font-size-medium);
  padding: 12px;
  padding-right: 30px;
  outline: 0 none;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
}

.dropdown-input-success,
.dropdown-input-error
{
  padding-right: 55px;
}

.dropdown-input-active {
  color: var(--wl-text-color-default);
}

.dropdown-input-container {
  background: var(--wl-brand-color-three);
  border: 1px solid var(--wl-input-box-border-color);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  height: 43px;
  width: 100%;
}

.dropdown-input-container:before {
  background-image: url(../../icons/chevron-down.svg);
  background-repeat: no-repeat;
  background-origin: content-box;
  content: '';
  height: 24px;
  position: absolute;
  padding: 10px;
  pointer-events: none;
  right: 0;
  width: 24px;
}

.dropdown-input-container-open:before {
  transform: scaleY(-1);
}

.dropdown-input-container-error:before,
.dropdown-input-container-success:before {
  padding: 9px 33px 9px 9px;
}

.dropdown-input-container-error {
  border-color: var(--wl-background-color-eight);
}

.dropdown-input-container-success {
  border-color: var(--wl-background-color-seven);
}

.dropdown-input-container:after {
  background-origin: content-box;
  background-repeat: no-repeat;
  height: 24px;
  padding: 10px;
  position: absolute;
  pointer-events: none;
  right: 0;
  width: 24px;
}

.dropdown-input-container-error:after {
  background-image: url(../../icons/cancel.svg);
  content: '';

}

.dropdown-input-container-success:after {
  background-image: url(../../icons/check_circle.svg);
  content: '';
}

.dropdown-message {
  color: var(--wl-text-color-twelve);
  display: block;
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-default);
  position: absolute;
}

.dropdown-message-error {
  color: var(--wl-background-color-eight);
}
